
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-face-abnes {
    font-family: "Abnes";
    margin-top: 21px;
}

@font-face {
    font-family: "Abnes";
    src: local("Abnes"),
    url("./fonts/abnes.ttf") format("truetype");
}

.logo {

    margin: 15px 10px auto !important;
}

@media screen and  (min-width: 900px) {
    .labelTop {
        font-size: 60px;
        letter-spacing: 0.02em;
    }
}

@media screen and  (max-width: 900px)and (min-width: 878px) {

    .labelTop {
        font-size: 60px;
        letter-spacing: 0.02em;
    }
}

@media screen and  (max-width: 878px)and (min-width: 700px) {

    .labelTop {
        font-size: 45px;
        letter-spacing: 0.02em;
    }

    .description-home-page {
        font-size: 130% !important;;
    }

    .description-about {
        font-size: 120% !important;;
    }
}

@media screen and  (max-width: 700px)and (min-width: 650px) {

    .labelTop {
        font-size: 40px !important;
    }

    .description-home-page {
        font-size: 120% !important;;
    }

    .description-about {
        font-size: 100% !important;;
    }
}

@media screen and  (max-width: 650px)and (min-width: 500px) {

    .labelTop {
        font-size: 30px !important;
    }

    .description-home-page {
        font-size: 120% !important;
    }

    .description-about {
        font-size: 100% !important;
    }

}

@media screen and  (max-width: 500px) {

    .labelTop {
        letter-spacing: 0.05em !important;
        font-size: 22px !important;
    }

    .font-face-abnes {
        font-size: 12px !important;
    }

    .description-home-page {
        font-size: 100% !important;;
    }

    .description-about {
        font-size: 100% !important;
    }
}

.subtitle {
    margin-top: 7%;
    margin-right: 5%;
    letter-spacing: 0.05em;
    font-family: Montserrat, sans-serif;
    font-size: 50%;
    color: white;
}

.main {
    padding-top: 44px;
}

.paddingBottom {
    padding-bottom: 80px!important;
}
.paddingTop{
    padding-top: 44px;
}


.images-description {
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 3vw;
    font-weight: bold;
}

.lighter {
    font-weight: lighter;
}

.description-home-page {
    font-family: Montserrat, sans-serif;
    font-size: 140%;

    font-weight: bold;
    max-width: 800px;
    margin: 4vw auto;
    padding: 20px;
}

.description-about {
    font-family: Montserrat, sans-serif;
    font-size: 130%;
    font-weight: bold;
    max-width: 800px;
    padding: 1.5vw .5vw;
}

.smallerImg {
    margin: auto !important;
    width: 80vw;
    height: auto;
}


.labelTop {
    margin-top: -54%;
    letter-spacing: 0.20em;
    left: 44%;
    position: absolute;
    font-family: Montserrat, sans-serif;
    font-weight: bolder;
    /*background-color: rgba(186, 213, 231, 0.12) !important;*/
    background-color: rgba(106, 142, 175, 0.1) !important;
    padding-left: 10px;
    padding-bottom: 10px;
    color: black;
    /*border-left: rgba(115, 115, 28, 0.8) solid 1px;*/
}


.item {
    font-family: 'Montserrat', sans-serif !important;
}

/*images -- in about page */
/* Create two equal columns that floats next to each other */
.columnImageSmallSize {
    float: left;
    width: 20%;
    padding: 10px;
}

.columnImageMiddleSize {
    float: left;
    width: 40%;
    padding: 10px;
}

.columnAddress {
    float: left;
    width: 5%;
    padding: 10px;
}


.columnTextLarge {
    float: left;
    width: 80%;
    padding: 1% 10% 2%;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold;
}

.columnTextMiddle {
    float: left;
    width: 60%;
    /*padding: 1% 2% 2%;*/
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold;

}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.middleText {
    margin-top: 20% !important;
}

.marginNonMobile {
    margin-top: 1vw;
}


.imgTracking{
    margin-top: 7%;
}


/* Responsive layout */
@media screen and (max-width: 600px) {

    .trademarks-text-bottom{
     font-size: 70%;
    }

    #root > div > div.main > div:nth-child(3) > div.columnImageMiddleSize{
        width: 100% !important;
    }

    .imgTracking{
        margin-top: 0%;
    }


    .arthroTechText{
        width: 100% !important;
    }
    .marginNonMobile {
        margin-top: 0;
    }

    .columnImageSmallSize {
        width: 100%;
    }

    .columnTextLarge {
        width: 70%;
        padding: 2%;
    }

    .tracking {
        margin-left: 5px;
        width: 100%;
    }

    .columnImageMiddleSize {
        width: 70%;
        height: 70%;
        margin: auto;
    }

    .columnTextMiddle {
        width: 100%;
        padding: 2%;
    }

    .columnTextLarge {
        width: 100%;
        padding: 2%;
    }

    .footerBottom {
        padding-bottom: 5vw;
    }

    .row-mobile {
        border: #638dad solid 1px;
        border-radius: 3px;
        margin: 2vw;
    }

    .middleText {
        margin-top: 0 !important;
    }
}


/*//-----------------------------*/

/* Container holding the image and the text */
.container {
    position: relative;
    text-align: center;
    color: white;
}

/* Top left text */
.top-left {
    position: absolute;
    top: 8px;
    left: 16px;
}


